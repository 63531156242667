<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-sourceObject`"
        v-model="properties.sourceObject"
        outlined
        dense
        :label="$lang.labels.sourceObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
  </v-row>
</template>

<script>
import StepMixin from './StepMixin'

export default {
  name: 'EscCharsStep',
  mixins: [StepMixin]
}
</script>
